import "./sticky-ref.scss";
export const component = () => {
  return {
    init() {
      this.elAboveSticky = document.getElementById("elAboveSticky");
      this.headerNavHeight = document.getElementById("siteHeader").getBoundingClientRect().height;
      const nav = document.getElementById("categoryNav");
      const viewOptionSwitch = document.getElementById("viewOptionSwitch");
      this.$refs.stickyRef.style.height = `${this.elAboveSticky.getBoundingClientRect().bottom + window.scrollY + window.innerHeight * 0.4}px`;
      this.observer = new IntersectionObserver(
        ([entry]) => {
          if (!entry.isIntersecting) {
            nav.classList.add("isSticky");
            viewOptionSwitch.classList.add("isSticky");
          } else {
            nav.classList.remove("isSticky");
            viewOptionSwitch.classList.remove("isSticky");
          }
        },
        {
          rootMargin: `${this.headerNavHeight * -1}px 0px 0px 0px`
          // Move the observer’s viewport 44px upwards
          // threshold: [0], // Trigger when any part of the sentinel is out of view
        }
      );
      this.observer.observe(this.$refs.stickyRef);
    },
    onResize() {
      if (this.sentiel) {
        this.$refs.stickyRef.style.height = `${this.elAboveSticky.getBoundingClientRect().bottom + window.scrollY + window.innerHeight * 0.4}px`;
      }
    },
    destroy() {
      if (this.observer) {
        this.observer.disconnect();
      }
    }
  };
};
export default () => {
  return component;
};
