import Alpine from "alpinejs";
import AlpinePersist from "@alpinejs/persist";
import AlpineFocus from "@alpinejs/focus";
import AlpineIntersect from "@alpinejs/intersect";
let isInitialized = false;
window.Alpine = window.Alpine || Alpine;
import components from "./config.components.js";
const stores = {};
function registerComponents(components2) {
  Object.keys(components2).forEach(
    (name) => registerComponent(name, components2[name])
  );
}
export function registerComponent(name, component) {
  Alpine.data(name, component());
}
export function registerStore(store) {
  const { data, persist, name } = store;
  persist?.forEach(
    (key) => data[key] = Alpine.$persist(data[key]).as(`${name}.${key}`)
  );
  stores[name] = store;
  Alpine.store(name, data);
  return store;
}
export function getStore(name) {
  return Alpine.store(name);
}
export default async function init() {
  window.dispatchEvent(
    new CustomEvent("alpine:config:init", {
      detail: { Alpine, registerComponent, registerComponents }
    })
  );
  Alpine.plugin(AlpinePersist);
  Alpine.plugin(AlpineFocus);
  Alpine.plugin(AlpineIntersect);
  registerComponents(components);
  if (!isInitialized) {
    isInitialized = true;
    Alpine.start();
  }
}
