import { Location, updateHistoryRecord } from "swup";
export const component = () => {
  return {
    init() {
      const { swup } = window;
      this.linkAnchorHandler = (visit) => {
        const { url, hash } = Location.fromElement(visit.trigger.el);
        updateHistoryRecord(url + hash);
        const options = { behavior: "smooth" };
        const scrollTarget = visit.to.hash;
        swup.hooks.callSync(
          "scroll:anchor",
          visit,
          { hash: scrollTarget, options },
          (visit2, { hash: hash2, options: options2 }) => {
            hash2 = hash2 ? hash2.substring(1) : null;
            if (hash2 === "texte") {
              this.headerNavHeight = document.getElementById("siteHeader").getBoundingClientRect().height;
              this.categoryNavHeight = document.getElementById("categoryNav").getBoundingClientRect().height;
              this.viewOptionSwitchHeight = document.getElementById("viewOptionSwitch").getBoundingClientRect().height;
              this.navsHeight = this.headerNavHeight + this.categoryNavHeight + this.viewOptionSwitchHeight;
              this.anchorElement = document.getElementById(hash2);
              this.anchorTop = this.anchorElement ? this.anchorElement.getBoundingClientRect().top : 0;
              this.targetY = this.anchorTop + window.scrollY - this.navsHeight;
              window.scroll({
                top: this.targetY,
                behavior: "smooth"
              });
            } else {
              const anchor = document.getElementById(hash2);
              if (anchor) {
                anchor.scrollIntoView(options2);
              }
            }
          }
        );
      };
      swup.hooks.replace("link:anchor", this.linkAnchorHandler);
    },
    destroy() {
    }
  };
};
export default () => {
  return component;
};
