export const component = () => {
  return {
    enteringUrl: null,
    enteringHash: null,
    leavingUrl: null,
    teasersElement: null,
    teasersTop: 0,
    targetY: 0,
    headerNavHeight: 0,
    categoryNavHeight: 0,
    navsHeight: 0,
    needToWaitForScroll: false,
    transitionFade: false,
    longer: false,
    shorter: false,
    init() {
      const { swup: swup2 } = window;
      this.handlerVisitStart = (visit) => {
        this.checkUrl(visit);
        this.checkFade();
        this.checkDiretion();
        if (this.enteringUrl.includes("/texte/kategorien") && !this.leavingUrl.includes("/texte/kategorien")) {
          this.scrollForLeaving(visit);
        }
      };
      this.handlerAnimationOutEnd = async (visit) => {
        if (this.needToWaitForScroll) {
          this.checkUrl(visit);
          await this.waitForScroll(visit);
        }
      };
      this.handlerContentReplace = (visit) => {
        this.checkUrl(visit);
        this.scrollForEntering(visit);
      };
      swup2.hooks.on("visit:start", this.handlerVisitStart);
      swup2.hooks.on("animation:out:end", this.handlerAnimationOutEnd);
      swup2.hooks.on("content:replace", this.handlerContentReplace);
    },
    checkUrl(visit) {
      this.enteringUrl = String(visit.to.url.replace(/\/page:\d+/, ""));
      this.enteringHash = visit.to.hash;
      this.leavingUrl = String(visit.from.url.replace(/\/page:\d+/, ""));
    },
    checkFade() {
      if (this.enteringUrl == "/" && this.enteringHash !== "#texte" && this.leavingUrl.includes("/texte/kategorien")) {
        this.transitionFade = true;
      } else {
        this.transitionFade = false;
      }
    },
    checkDiretion() {
      if (this.enteringUrl == "/" && this.leavingUrl.includes("/texte/kategorien")) {
        this.longer = true;
      } else if (this.enteringUrl.includes("/texte/kategorien") && this.leavingUrl == "/") {
        this.shorter = true;
      }
    },
    scrollForLeaving() {
      this.headerNavHeight = document.getElementById("siteHeader").getBoundingClientRect().height;
      this.categoryNavHeight = this.$refs.categoryNav.getBoundingClientRect().height;
      this.navsHeight = this.headerNavHeight + this.categoryNavHeight;
      this.teasersElement = document.getElementById("texte");
      this.teasersTop = this.teasersElement ? this.teasersElement.getBoundingClientRect().top : 0;
      this.targetY = this.teasersTop + window.scrollY - this.navsHeight;
      if (this.teasersTop - 1 > this.navsHeight) {
        this.needToWaitForScroll = true;
        console.log(this.needToWaitForScroll);
        window.scroll({
          top: this.targetY,
          behavior: "smooth"
        });
      }
    },
    waitForScroll() {
      this.needToWait = false;
      return new Promise((resolve) => {
        const checkScroll = () => {
          const currentScrollY = window.scrollY;
          console.log(currentScrollY, this.targetY);
          if (Math.abs(currentScrollY - this.targetY) < 1) {
            resolve();
          } else {
            window.requestAnimationFrame(checkScroll);
          }
        };
        window.scroll({
          top: this.targetY,
          behavior: "smooth"
        });
        window.requestAnimationFrame(checkScroll);
      });
    },
    scrollForEntering(visit) {
      if (this.enteringUrl.includes("/texte/kategorien")) {
        window.scroll({
          top: 0
        });
      } else if (
        // scroll to top when enter home from home with hash
        this.leavingUrl === "/" && this.enteringUrl === "/" && this.enteringHash !== "#texte"
      ) {
        window.scroll({
          top: 0,
          behavior: "smooth"
        });
      }
    },
    destroy() {
      swup.hooks.off("visit:start", this.handlerVisitStart);
      swup.hooks.off("animation:out:end", this.handlerAnimationOutEnd);
      swup.hooks.off("content:replace", this.handlerContentReplace);
    }
  };
};
export default () => {
  return component;
};
