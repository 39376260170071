import "./scss/main.scss";
import { default as initAlpine } from "./js/config.alpine.js";
import { default as initSwup } from "./js/config.swup.js";
async function init() {
  await initSwup();
  initAlpine();
  import(
    /* webpackChunkName: "lazysizes" */
    "./lazysizes.js"
  );
}
init();
