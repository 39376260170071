import Swup from "swup";
import SwupFragmentPlugin from "@swup/fragment-plugin";
export let swup = null;
export default async () => {
  if (swup) {
    return swup;
  }
  const plugins = [
    new SwupFragmentPlugin({
      // debug: true,
      rules: [
        {
          from: [
            "/",
            "/texte/kategorien/:filter?"
            // "/page:pagination?",
            // "/texte/kategorien/:filter?/page:pagination?",
          ],
          to: [
            "/",
            "/texte/kategorien/:filter?"
            // "/page:pagination?",
            // "/texte/kategorien/:filter?/page:pagination?",
          ],
          containers: ["#swupFragment"]
        }
      ]
    })
    // new SwupHeadPlugin(),
    // new SwupPreloadPlugin(),
    // new SwupProgressPlugin(),
    // new SwupMatomoPlugin(),
  ];
  swup = new Swup({
    plugins,
    containers: ["main.swup-container"],
    cache: true,
    // cache: process.env.NODE_ENV !== "development",
    linkToSelf: "navigate",
    ignoreVisit: (url, { el } = {}) => {
      if (el?.closest("[data-no-swup]")) {
        return true;
      }
      return false;
    }
  });
  window.addEventListener("navigate", ({ detail: { url, options } }) => {
    swup.navigate(url, options);
  });
  window.swup = swup;
  return swup;
};
