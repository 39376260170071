import CategoryNav from "./components/CategoryNav/CategoryNav.js";
import Navigation from "./components/Navigation/Navigation.js";
import StickyRef from "./components/StickyRef/StickyRef.js";
import ViewOptionSwitch from "./components/ViewOptionSwitch/ViewOptionSwitch.js";
export default {
  CategoryNav,
  Navigation,
  StickyRef,
  ViewOptionSwitch
};
