import "./view-option-switch.scss";
import { registerStore } from "@/js/config.alpine.js";
const store = {
  data: {
    withImage: true
  },
  name: "viewOptionSwitch"
};
export const component = () => {
  return {
    get withImage() {
      return this.$store[store.name].withImage;
    },
    set withImage(value) {
      this.$store[store.name].withImage = value;
    },
    init() {
      this.toggleClassList(this.withImage);
      this.$watch(`withImage`, (value) => this.toggleClassList(value));
    },
    showImage() {
      this.withImage = true;
    },
    showOnlyText() {
      this.withImage = false;
    },
    toggleClassList(value) {
      if (value) {
        document.getElementById("texte").classList.remove("without-img-teasers");
      } else {
        document.getElementById("texte").classList.add("without-img-teasers");
      }
    },
    destroy() {
    }
  };
};
export default () => {
  registerStore(store);
  return component;
};
